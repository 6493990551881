import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import React from 'react';
import { RejectedTransactionDetail } from 'src/components/molecules/rejectedTransactionDetail';
import Viewport from 'src/shared/hooks/viewport';
import { graphql, useStaticQuery } from 'gatsby';
import { WrapperTransactionRejected } from './styled';

const containerRejectedTransaction = () => {
    const isBrowser = () => typeof window !== 'undefined';
    const urlParams = new URLSearchParams(isBrowser()? window.location.search : '');
    const order = urlParams.get('order');

    const dataRejectedState = useStaticQuery(graphql`
        query rejectedStateQuery {
            allContentfulPaginaInternaFooter(
                filter: {page: {eq: "ConfirmacionCompraRechazada"}}
            ) {
                nodes {
                    title
                    slug
                    pageIcon {
                        file {
                            url
                        }
                    }
                    metaData {
                        title
                        descripcion
                        keyWords
                    }
                }
            }
        }
    `);

    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Confirmación rechazada',
            pathname: `confirmacion/rechazada?order=${order}`
        }
    ];

    const {
        allContentfulPaginaInternaFooter: {nodes}
    } = dataRejectedState;

    const data = nodes[0];

    return (
        <Layout>
            <SEO 
                title={data.metaData.title}
                description={data.metaData.descripcion}
                keyWords={data.metaData.keyWords}
                slug={data.slug}
                />
            <WrapperTransactionRejected>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}
            </WrapperTransactionRejected>
            <RejectedTransactionDetail idPayment={order || ''} data={data}/>
        </Layout>
    )
}

export default containerRejectedTransaction;