import React, { useContext, useEffect, useState } from 'react';
import { Circle, CircleFill, Container, ContainerGame, ContainerImage, ContentDateState, ContentTitle, InfoGame, MessageTitle, NameBuyer, NameState, OrderDetail, Text, TitleDetails, TotalValue, TransactionContainer, TransactionDetail, TransactionInformation, TransactionNumber } from './styled';
import { colorText } from '@utils/colorText';
import { Button } from '@componentsShared';
import siteUrl from '@utils/siteUrl';
import { AppContextUser } from 'src/shared/context/user';
import { getData } from '@utils/transferData';
import CryptoJS from 'crypto-js';
import { carServices } from '@services/car';
import { IPaymentDetail, ITransaction } from './types';
import moment from 'moment';
import { converterNumber } from '@utils/decimalNumberConverter';
import { graphql, useStaticQuery } from 'gatsby';
import { TooltipCustom } from 'src/shared/components/atoms/inputCustom/styled';
import { getDataModalOder } from '@utils/history';

type IProps = {
    idPayment: string
    data: any
}

export const RejectedTransactionDetail = (props: IProps) => {
    let transactionEmpty: ITransaction = {
        createdAt: '',
        reference: '',
        responseJson: '',
        state: false,
        total: 0,
        __typename: ''
    };

    let detailEmpty: IPaymentDetail = [
    {
        sigla: ' '
    }]

    const isBrowser = () => typeof window !== 'undefined';
    const { dataUser, setDataUser } = useContext(AppContextUser);
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState<boolean>();
    const [dataTransaction, setdataTransaction] = useState<ITransaction>(transactionEmpty);
    const [dateCreate, setDateCreate] = useState('');
    const [detail, setDetail] = useState<[] | IPaymentDetail>(detailEmpty);
    const { state, createdAt: date, total, reference } = dataTransaction;

    const homePage = () => {
        if(isBrowser()) {
            window.location.href = '/';
        }
    }

    const approvedPage = () => {
        if(isBrowser()) {
            window.location.href = `/confirmacion/aprobada?order=${props.idPayment}`;
        }
    };

    const paymentDetail = async () => {
        await carServices
            .getPaymentDetail(props.idPayment)
            .then((data) => {
                setDetail(data.data.getPaymentDetail);
            })
            .catch((err) => {
                console.log('Error al obtener detalle de la compra rechazada: ', err)
            })
    };

    const getTransactionState = async (
        token: string,
        tipoDocumento: string,
        ideUsuario: string
    ) => {
        await carServices
            .transactionState(token, tipoDocumento, ideUsuario, props.idPayment)
            .then((data) => {
                const dataInitial = data.data.getPayment;
                if(dataInitial == null) {
                    homePage();
                }
                const responseJson = JSON.parse(dataInitial.responseJson);
                const dataTransaction = getDataModalOder(responseJson, props.idPayment);
                let date = moment(dataTransaction.date).format('dddd,  D  MMMM  YYYY, h:mm A');
                const state = dataInitial.state === 'APPROVED' ? true : false;
                if(state) {
                    approvedPage();
                }
                setdataTransaction(dataInitial);
                setLoading(state ? true : false);
                setdataTransaction((previousData) => {
                    return {
                        ...previousData,
                        state: state ? true : false,
                        createdAt: date
                    };
                });
                setDateCreate(dataInitial.createdAt);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const gameLogos = useStaticQuery(graphql`
        query gameLogos {
            allContentfulConfiguracionJuego {
                nodes {
                    logo {
                        file {
                            url
                        }
                    }
                    abbreviation
                }
            }
        }
    `);

    const {
        allContentfulConfiguracionJuego: {nodes}
    } = gameLogos;

    const logos = nodes

    const mapaDeUrls = logos.reduce((map: any, obj: any) => {
        map.set(obj.abbreviation, obj.logo.file.url);
        return map;
    }, new Map());

    useEffect(() => {
        let dataLocalStorage = getData('userData');
        if((!dataLocalStorage.user || dataLocalStorage.user === '')) {
            homePage();
        }
        if (dataLocalStorage) {
            setDataUser(dataLocalStorage);
            let token = CryptoJS.AES.decrypt(
                dataLocalStorage.token,
                process.env.ENCRYPTION_KEY || ''
            );
            setToken(token.toString(CryptoJS.enc.Utf8));
            getTransactionState(
                token.toString(CryptoJS.enc.Utf8),
                dataLocalStorage.type,
                dataLocalStorage.user
            );
            paymentDetail();
        } else {
            homePage();
        }
    }, []);

    return (
        <Container>
            <MessageTitle>
                <Circle>
                    <CircleFill>
                        <img src={props.data.pageIcon.file.url}/>
                    </CircleFill>
                </Circle>
                <NameState dangerouslySetInnerHTML={{ __html: colorText(props.data.title || '') }} />
                <Button text='Seguir jugando' onClick={() => window.location.href = siteUrl + '/juegos'}></Button>
            </MessageTitle>
            <TransactionContainer>
                <TransactionDetail>
                    <TitleDetails>Datos de transacción</TitleDetails>
                    <ContentDateState>
                        <p>{date}</p>
                        <span>Rechazada</span>
                    </ContentDateState>
                    <TransactionInformation>
                        <Text>
                            <label>Referencia</label>
                            <h3>{reference}</h3>
                        </Text>
                        <Text>
                            <label>Nombre del comprador</label>
                            <NameBuyer>{dataUser.nombreCompleto?.toLocaleLowerCase()}</NameBuyer>
                        </Text>
                        <Text>
                            <label>Correo electrónico</label>
                            <h3>{dataUser.email}</h3>
                        </Text>
                        <Text>
                            <TotalValue>
                                <label>Total pagado</label>
                                <h2>$ {converterNumber(total)}</h2>
                            </TotalValue>
                        </Text>
                    </TransactionInformation>
                </TransactionDetail>
                <OrderDetail>
                    <ContentTitle>
                        <TitleDetails>Detalles del pedido</TitleDetails>
                    </ContentTitle>
                    {detail?.map((det: any, index: any) => (
                        <ContainerGame>
                            <InfoGame>
                                <ContainerImage>
                                    <img src={mapaDeUrls.get(det.sigla == 'SEM'? 'CHA' : det.sigla)}></img>
                                </ContainerImage>
                                <label>
                                    No. de transacción
                                    <span
                                        className="icon-info"
                                        data-tip
                                        data-iscapture
                                        data-event="click focus"
                                        data-for="transaction"
                                    />
                                </label>
                                <TooltipCustom
                                    multiline={true}
                                    id="transaction"
                                    type="light"
                                    place="bottom"
                                    offset={{ left: 95 }}
                                    effect="solid"
                                >
                                    La clave para abrir tu apuesta es tu número de
                                    identificación
                                </TooltipCustom>
                                <TransactionNumber><span/></TransactionNumber>
                            </InfoGame>
                        </ContainerGame>
                    ))}
                    <Button text='Historial de compras' onClick={() => window.location.href = siteUrl + '/historial-de-compras'}></Button>
                </OrderDetail>
            </TransactionContainer>
        </Container>
    )
}