import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const WrapperTransactionRejected = styled.div`
    width: 100%;
    height: 100%;
    padding: 17px 17px 40px;
    ${breakpoint('md')`
        margin: auto;
        padding: 36px;

    `};
`;