import moment from "moment";
import { PayvalidaModel } from "./models/payvalida";
import { PlacetoPayModel } from "./models/placetopay";


interface ModalOrderModel {
    name: string;
    email: string;
    date: string;
    status: string;
    franchise: string;
    total: number;
    reference: string;
    bank: string;
    cus: string;
}

export const processResponse = (response: string) => {
    const data: PayvalidaModel | PlacetoPayModel = JSON.parse(response);
    return data;
}

export const getDataModalOder = (data: PayvalidaModel | PlacetoPayModel, order: any): ModalOrderModel => {
    const modalOrderInfo = {
        name: '',
        email: '',
        date: '',
        status: '',
        franchise: '',
        total: 0,
        reference: '',
        bank: '',
        cus: ''
    };

    if ('requestId' in data) {
        modalOrderInfo.name = data.request.buyer.name;
        modalOrderInfo.email = data.request.buyer.email;
        modalOrderInfo.total = data.request.payment.amount.total;
        modalOrderInfo.reference = data.request.payment.reference.toString();

        modalOrderInfo.date = data.status.date;
        modalOrderInfo.status = getStatus(data.status.status);

        if (data.payment && data.payment[0]) {
            modalOrderInfo.franchise = data.payment[0].franchise;
            modalOrderInfo.cus = data.payment[0].authorization;
        }
    } else if ('CODE' in data) {
        if (data.DATA) {
            modalOrderInfo.email = data.DATA.EMAIL;
            modalOrderInfo.date = data.DATA.UDPATE_DATE;
            modalOrderInfo.reference = data.DATA.ORDER;
            modalOrderInfo.status = getStatus(data.DATA.STATE);
            modalOrderInfo.total = Number(data.DATA.AMOUNT);

            if (!data.DATA.TRANSACTION) {
                return modalOrderInfo;
            }

            if (data.DATA.TRANSACTION.RESPONSE_CODE) {
                modalOrderInfo.status = getStatus(data.DATA.TRANSACTION.RESPONSE_CODE);
            }

            modalOrderInfo.franchise = data.DATA.TRANSACTION.FRANCHISE;
            modalOrderInfo.cus = data.DATA.TRANSACTION.CUS;
        }

    }
    return modalOrderInfo;
}

export const getDataOrder = (data: PayvalidaModel | PlacetoPayModel, id: number, reference: string, date: string) => {
    const dataOrder = {
        order: '',
        reference: reference,
        date: parseDate(date),
        id: id
    };

    if ('requestId' in data) {
        dataOrder.order = `${data.request.payment.reference}`;
    } else if ('CODE' in data) {
        if (data.DATA) {
            dataOrder.order = `${data.DATA.CODE}`;
        }
    }
    return dataOrder;
}

export const getOrder= (data: PayvalidaModel | PlacetoPayModel) => {
    if ('requestId' in data) {
        return `${data.request.payment.reference}`;
    } else if ('CODE' in data) {
        if (data.DATA) {
            return `${data.DATA.CODE}`;
        }        
    }
    return ""
}

const parseDate = (date: string) => {
    if (/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/.test(date)) {
        return date;
    }
    return moment(date).format("YYYY-MM-DD");
}


export const getStatus = (status: string) => {
    status = `${status}`.toUpperCase()
    if (status == "APPROVED" || status === "REJECTED") {
        return status;
    }
    if (status == "APROBADA") {
        return "APPROVED";
    }
    if (status == "PENDIENTE") {
        return "PENDING";
    }
    return "REJECTED";
}